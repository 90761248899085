/* @page {
    size: A4;
    margin: 0;
  }

  @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  } */
  .page{
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    .page{
      width: 100%;
    }
  }