.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-danger {
  background-color: #e91e63 !important;
}
.label-success {
  background-color: #66aa18 !important;
}
.school_name{
  font-size: 50px;
}
.branchLogo{
  width: 127px;
  height: 126px;
  float: right;
}
.navBar{
  display: flex;
  /* justify-content:"space-between"; */
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
}
.header_div{
  display: grid;
  grid-template-columns: 17fr 36fr 1fr;
}

.headerDetails{
  font-size:16px;
  padding-left: 5px;
}

.successCard{
  width: 450px !important;
}

.sdetails{
  width: 90%;
}

@media screen and (max-width: 600px) {
  .school_name{
    font-size: 20px;
  }
  .school_header{
    text-align: center;
  }
  .branchLogo{
    height: 100px;
    width: 100px;
  }
  .header_div{
    display: grid;
    grid-template-columns: 12fr 36fr 1fr;
  }

  .navBar{
    display: flex;
    justify-content:"space-between";
    /* justify-content: center; */
    align-items:center;
    flex-wrap: wrap;
  }
  .headerDetails{
    font-size:13px;
  }
  .headerDetails2{
    font-size:11px;
  }
  .bgImageLogin{
    display:none;
  }

  .logincardcss{
    display:none !important;
  }
  .successCard{
    width: 95% !important;
  }
  .sdetails{
    width: 100%;
  }
  
}
